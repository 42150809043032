$.getScript("/js/scripts/easing.js");


function getTickerWidth() {
    tickerwidthprod = $("#ticker-producenten").css("width");
    tickerwidthket = $("#ticker-ketens").css("width");
}

getTickerWidth();

$("#ticker-producenten, #ticker-producenten-2").css("right", "-" + tickerwidthprod);
$("#ticker-ketens, #ticker-ketens-2").css("right", "-" + tickerwidthket);

function moveTickerKet() {
    $("#ticker-ketens, #ticker-ketens-2").css("right", "-" + tickerwidthket);

    $("#ticker-ketens, #ticker-ketens-2").animate({
        "right": $(window).width()
    }, parseInt(tickerwidthket) * 18, "linear", function () {
        moveTickerKet();
    });
}

function moveTickerProd() {
    $("#ticker-producenten, #ticker-producenten-2").css("right", "-" + tickerwidthprod);

    $("#ticker-producenten, #ticker-producenten-2").animate({
        "right": $(window).width()
    }, parseInt(tickerwidthprod) * 18, "linear", function () {
        moveTickerProd();
    });
}


function getSlideWidth() {
    slidewidth = $("#slide").css("width");
}


$(document).ready(function () {
    $(".producten").find("tr:even").css("background-color", "#cce8f6");
    moveTickerProd();
    moveTickerKet();
    getSlideWidth();
    $("#slide").css("right", "-" + slidewidth).show();
});

$(window).resize(function () {
    getTickerWidth();
    getSlideWidth();
    if ($("#slide").css("right") != "0px") {
        $("#slide").css("right", "-" + slidewidth)
    }
});

function slideShow() {
    $("#slide").stop().animate({
        "right": "0"
    }, 700, "easeInOutCubic");
}

function hideSlide() {
    $("#slide").stop().animate({
        "right": "-" + slidewidth
    }, 700, "easeInOutCubic");
}


$("#help").click(function () {
    $(".slide-inner").hide();
    $("#slide-help").show();
    slideShow();
});

$("#close-slide, .kies-soort, .kies-kaart, #filters *").click(function () {
    hideSlide();
});

$("#zoomin").click(function () {
    zoomin();
});

$("#zoomout").click(function () {
    zoomout();
});

function zoomto(latilongi){
    var latilongi = latilongi.split(",");
    var lati = parseFloat(latilongi[0]);
    var longi = parseFloat(latilongi[1]);

    localStorage['zoom'] = 11;
    map.setZoom(11);
    map.setCenter(new google.maps.LatLng(lati,longi));
}

function zoomin(){
    var zoomlevel = map.getZoom();

    localStorage['zoom'] = zoomlevel + 1;

    map.setZoom(zoomlevel + 1);
}

function zoomout(){
    var zoomlevel = map.getZoom();

    localStorage['zoom'] = zoomlevel - 1;

    map.setZoom(zoomlevel - 1);

}


/* image fills its parent <div class="filling-image" style="width:300px; height:200px;"><img src="Woody.jpg" /></div> */

if ($('.filling-image').length > 0) {
    $.getScript("/js/scripts/imgLiquid-min.js", function () {
        $(".filling-image").imgLiquid();
    });
}


/* placeholder in IE8 */

if (($('#var-ieplaceholder').css('font-family') == 'true') && ($('form').length > 0)) {
    $('[placeholder]').focus(function () {
        var input = $(this);
        if (input.val() == input.attr('placeholder')) {
            input.val('');
            input.removeClass('placeholder');
        }
    }).blur(function () {
        var input = $(this);
        if (input.val() == '' || input.val() == input.attr('placeholder')) {
            input.addClass('placeholder');
            input.val(input.attr('placeholder'));
        }
    }).blur();
}


(function ($) {
    $.widget("custom.combobox", {
        _create: function () {
            this.wrapper = $("<span>")
                .addClass("custom-combobox")
                .insertAfter(this.element);

            this.element.hide();
            this._createAutocomplete();
            this._createShowAllButton();
        },

        _createAutocomplete: function () {
            var selected = this.element.children(":selected"),
                value = selected.val() ? selected.text() : "";

            this.input = $("<input>")
                .appendTo(this.wrapper)
                .val(value)
                .attr("title", "")
                .addClass("custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left")
                .autocomplete({
                    delay: 0,
                    minLength: 0,
                    source: $.proxy(this, "_source")
                })
                .tooltip({
                    tooltipClass: "ui-state-highlight"
                });

            this._on(this.input, {

                autocompleteselect: function (event, ui) {

                    ui.item.option.selected = true;
                    this._trigger("select", event, {
                        item: ui.item.option
                    });

                    document.form1.submit();

                },

                autocompletechange: "_removeIfInvalid"
            });
        },

        _createShowAllButton: function () {
            var input = this.input,
                wasOpen = false;

            $("<a>")
                .attr("tabIndex", -1)
                .attr("title", "Show All Items")
                .tooltip()
                .appendTo(this.wrapper)
                .button({
                    icons: {
                        primary: "ui-icon-triangle-1-s"
                    },
                    text: false
                })
                .removeClass("ui-corner-all")
                .addClass("custom-combobox-toggle ui-corner-right")
                .mousedown(function () {
                    wasOpen = input.autocomplete("widget").is(":visible");
                })
                .click(function () {
                    input.focus();

                    // Close if already visible
                    if (wasOpen) {
                        return;
                    }

                    // Pass empty string as value to search for, displaying all results
                    input.autocomplete("search", "");
                });
        },


        _source: function (request, response) {
            var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
            response(this.element.children("option").map(function () {
                var text = $(this).text();
                if (this.value && ( !request.term || matcher.test(text) ))
                    return {
                        label: text,
                        value: text,
                        option: this
                    };
            }));
        },

        _removeIfInvalid: function (event, ui) {

            // Selected an item, nothing to do
            if (ui.item) {

                return;
            }

            // Search for a match (case-insensitive)
            var value = this.input.val(),
                valueLowerCase = value.toLowerCase(),
                valid = false;
            this.element.children("option").each(function () {
                if ($(this).text().toLowerCase() === valueLowerCase) {


                    this.selected = valid = true;
                    return false;
                }
            });

            // Found a match, nothing to do
            if (valid) {

                return;
            }

            // Remove invalid value
            this.input
                .val("")
                .attr("title", value + " didn't match any item")
                .tooltip("open");
            this.element.val("");
            this._delay(function () {
                this.input.tooltip("close").attr("title", "");
            }, 2500);
            this.input.autocomplete("instance").term = "";
        },

        _destroy: function () {
            this.wrapper.remove();
            this.element.show();
        }
    });
})(jQuery);


$(".combobox").combobox();
$("#toggle").click(function () {
    $(".combobox").toggle();
});
